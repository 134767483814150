import React, {useEffect, useState} from "react";

function InstallPwaButton() {
    const [isPwaInstalled, setIsPwaInstalled] = useState(false);
    const [showInstallAdvice, setShowInstallAdvice] = useState(false);
    const [pwaInstallButton, setPwaInstallButton] = useState(null);

    // @ts-ignore
    const isIOS = () => window.matchMedia('(display-mode: browser)').matches && /iPad|iPhone|iPod/.test(navigator.userAgent);

    // @ts-ignore
    const isUnableToInstall = () => window.matchMedia('(display-mode: browser)').matches && pwaInstallButton == null

    useEffect(() => {
        // @ts-ignore
        if (window.matchMedia('(display-mode: standalone)').matches) {
            setIsPwaInstalled(true);
        }

        // @ts-ignore
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            // @ts-ignore
            setPwaInstallButton(e);
        });
    }, []);

    const adviceText = () => {
        if (isIOS())
        {
            return (
                <div className="adviceText">
                    {/*// @ts-ignore*/}
                    <small>Appuyez sur <i className="icon-share-apple"></i> puis sur "Sur l'écran d'accueil (+)". </small>
                </div>
            )
        }
        if (isUnableToInstall())
        {
            return (
                <div className="adviceText">
                    <small>Cliquez sur les 3 points en haut à droite de votre navigateur puis sur "Installer l’application".</small>
                </div>
            )
        }
    }

    function installPwa() {
        if (isIOS() || isUnableToInstall()) {
            setShowInstallAdvice(!showInstallAdvice);
        } else if (pwaInstallButton) {
            // @ts-ignore
            pwaInstallButton.prompt();
        }
    }

    return (
        <>
            {showInstallAdvice &&
                <div className="install-advice install-advice--open">
                    <i className="icon-close install-advice__close" onClick={() => setShowInstallAdvice(!showInstallAdvice)}></i>
                    {adviceText()}
                </div>
            }
            <button type="button"
                    onClick={installPwa}
                    className="secondary-button">
            {
                !isPwaInstalled &&
                    (<>Installer l'app<i className="icon-download-bottom"></i></>)
            }
            </button>
        </>

    );
}

export default InstallPwaButton;
